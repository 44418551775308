@font-face {
  font-family: "Inter-Regular";
  src: local('Inter-Regular'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Inter-Bold";
  src: local('Inter-Bold'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
