$tiny: 600px;
$small: 650px;

.MapBodyDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $small) {
    display: none;
  }
}

.MapItemsDesktop {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 0;
  min-width: 0;
  margin-top: #{"min(20px, 2vh)"};
}

.MapHead {
  margin: 1vh 0px 0.5vh 0px;
}

.MapHead h1 {
  //fallback
  font-size: 40px;
  //optimal
  font-size: #{"clamp(30px, 5vh, 48px)"};
  margin: #{"min(14px, 0.5vh)"} 0px 0px 0px;
  color: white;
}

.MapItemsMobile {
  display: none;
  @media screen and (max-width: $small) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.MapItemsMobile select {
  background-color: white;
  border: 0;
  height: 40px;
  width: 50%;
  margin: auto;
  color: black;
  font-size: inherit;
  padding: 0.5em;
  padding-right: 2.5em;
  margin: 0;
  border-radius: 3px;
  text-indent: 0.01px;
  -moz-appearance: menulist-button;
  -webkit-appearance: menulist-button;
}

.MapItem {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  justify-content: flex-start;
  align-items: center;
  border: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 0px 15px #{"min(20px, 2.3vh)"} 15px;
  background-color: #fff;
  width: #{"min(28vw, 32vh)"};
  height: fit-content;
  min-height: 0;
  overflow: visible;
  max-height: 450px;
  border-radius: 20px;
  max-width: 330px;
  padding: 8px 0px;
  padding-top: 0px;
  @media screen and (max-width: $small) {
    width: 75%;
    margin: auto;
    margin-top: 5vh;
    padding: 0px;
  }
}

.MapItem h1 {
  font-size: #{"min(2.5vw, 3vh)"};
  margin: #{"min(0.67vw, 0.2vh)"} 0px;
  @media screen and (max-width: $small) {
    font-size: #{"min(5vw, 7vh)"};
    margin: #{"min(1.3vw, 1vh)"} 0px;
  }
}

.MapItem h2 {
  //fallback
  font-size: 20px;
  //optimal
  font-size: #{"clamp(16px, (1.5vw, 2.2vh), 26px)"};
  margin-top: 0;
  justify-content: center;
  width: 80%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $small) {
    font-size: #{"min(4vw, 5.5vh)"};
  }
}

.MapItem p {
  width: 75%;
  font-size: #{"min(13px, 1.6vh)"};
  margin: #{"min(9px, 0.3vh)"} 5px;
  @media screen and (max-width: $small) {
    font-size: 15px;
  }
}

.MapItem a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: #{"clamp(13px, (1.3vw, 2vh), 18px)"};
  padding: 6px 16px;
  margin: 4px 6px;
  border-radius: 20px;
  border-width: 0px;
  background-color: #2BB673;;
  text-decoration: none;
  vertical-align: middle;
  /* font-size: 1em; */
  color: white;
  width: 20%;
  height: #{"min(2.2vw, 2.6vh)"};
  max-width: 98px;
  max-height: 42px;
  @media screen and (max-width: $small) {
    font-size: #{"min(20px, 3vw, 3vh)"};
    height: 24px;
    margin-bottom: 15px;
  }
}

#scoreDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: #{"min(9px, 0.7vh)"};
}
#scoreDiv h1 {
  color: #2BB673;
  font-size: #{"min(2vw, 3vh)"};
  margin-bottom: 0;
  @media screen and (max-width: $small) {
    font-size: 30px;
  }
}
#scoreDiv p {
  font-size: #{"min(11px, 1.3vh)"};
  width: 100%;
  margin: 0px 0px 7px 0px;
  @media screen and (max-width: $small) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.RecordPlayerDiv {
  display: grid;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 105px;
  padding-bottom: 5px;
  // height: 120px;
}

.RecordPlayer {
  // position: absolute;
  z-index: 1;
  display: grid;
  margin-left: 0;
  grid-row: 1/2;
  grid-column: 1/2;
  // right: 97.2px;
}

.MapDisc {
  z-index: 2;
  margin-left: 12px;
  margin-bottom: 1px;
  display: grid;
  grid-row: 1/2;
  grid-column: 1/2;
  animation: spin 1s infinite linear;
  animation-play-state: paused;
  &.playing {
    animation-play-state: running;
  }
  // &:not(.playing) {
  //   animation: none;
  // }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(359deg);
  }
}

.Arm {
  // margin: 0px auto 5px auto;
  z-index: 4;
  position: relative;
  top: -72px;
  right: -41px;
  // right: 1px;
  display: grid;
  grid-row: 1/2;
  grid-column: 1/2;
  transform-origin: bottom right;
  transition: transform 0.4s;
  // animation: lowerArm 1s reverse forwards;
  &.playing {
    transform: rotate(-26deg);
  }
}

.Peg {
  z-index: 3;
  margin: auto;
  position: relative;
  right: 1px;
  top: 9px;
  display: grid;
  grid-row: 1/2;
  grid-column: 1/2;
}

.ChooseMapContainer {
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  max-width: 1575;
}

.Switch {
  width: 195px;
  //fallback
  height: 58px;
  //optimal
  height: #{"clamp(42px, 7vh, 69px)"};
  --dot: #000;
  --circle: #000;
  --background: hsla(0, 0%, 0%, 0.25);
  --duration: .3s;
  --text: #9EA0BE;
  --shadow: 0 1px 3px #{rgba(#00093D, .08)};
  cursor: pointer;
  position: relative;
  &:before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 160px;
      background: var(--background);
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: var(--shadow);
  }
  input {
      display: none;
      & + div {
          position: relative;
          span {
              line-height: 28px;
              background: #000;
              background-color: #000;
              width: 100%;
              &:before {
                  --x: 0;
                  --b: var(--circle);
                  --s: 4px;
                  background: #000;
                  content: 'World';
                  position: absolute;
                  left: 4px;
                  top: 4px;
                  width: 50%;
                  //fallback
                  height: 50px;
                  //optimal
                  height: #{"clamp(34px, 6vh, 60px)"};
                  border-radius: 160px;
                  transform: translateX(var(--x));
                  transition: transform var(--duration) ease;
              }
          }
      }
      &:checked {
        & + div {
          &:before {
            --s: 1;
          }
          &:after {
            --s: 0;
          }
          span {
            &:before {
              --x: 88px;
              --s: 12px;
              --b: var(--dot);
            }
          }
        }
      }
  }
}

#SwitchText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#SwitchText p {
  font-family: "Inter-Bold";
  color: white;
  opacity: 0;
  width: 47%;
  z-index: 1;
  transition: opacity 0.4s;
  &.active {
    opacity: 1;
  }
}

#beginnerDiv {
  position: relative;
  overflow: visible;
  width: 100%;
}

#beginnerText {
  position: absolute;
  font-family: "Inter-Bold";
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  width: 160px;
  height: 30px;
  color: white;
  background: #67BCFF;
  border-radius: 8px 8px 0 0;
  transform: translateY(-100%);
  line-height: 26px;
  text-align: center;
  z-index: -1;
  @media screen and (max-width: $small) {
    width: 200px;
  }
  @media screen and (max-height: $tiny) {
    width: 100px;
  }
}