$small: 650px;
$medium: 830px;

.header {
  // background-color: #fcfaf9;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 3vh;
  margin-top: 3vh;
  margin-bottom: 2vh;
  // min-height: 75px;
  width: 100%;
  @media screen and (max-width: $small) {
    align-items: center;
    margin-top: 0px;
    height: 5vh;
    min-height: 50px;
    padding-top: 1.5vh;
    margin-bottom: 1.5vh;
    // background-color: #333;
  }
}

#logoDesktop {
  margin-top: -0.5vh;
  margin-left: 3%;
  font-size: 27px;
  display: flex;
  align-items: flex-start;
  height: auto;
  color: white;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #0081af;
  }
  @media screen and (max-width: $medium) {
    font-size: 0px;
  }
  @media screen and (max-width: $small) {
    display: none;
  }
}

#logoMobile {
  display: none;
  align-items: flex-start;
  margin-top: -0.5vh;
  margin-left: 3%;
  font-size: 27px;
  height: auto;
  color: white;
  text-decoration: none;
  font-weight: bold;
  z-index: 4;
  @media screen and (max-width: $small) {
    display: flex;
  }
}

#logoMobile img {
  margin-top: 1vh;
  width: #{"min(78px, 10vh)"};
  height: auto;
}

#logoDesktop img {
  margin-top: -1.7vh;
  margin-right: 8px;
  width: #{"min(68px, 8vh)"};
  height: auto;
}

.navbar {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin-right: 3%;
  // justify-content: center;
  @media screen and (max-width: $small) {
    display: none;
  }
}

.navbar .linkButton {
  // padding: #{"min(10px, 2vh)"} #{"min(20px, 1.3vw)"};
  margin: 0px 16px;
  // border-radius: 6px;
  border-width: 0px;
  // background-color: #ddd;
  font-size: #{"min(24px, 2.5vw)"};
  color: white;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: white;
  }
}

.activeUnderline {
  // position: absolute;
  height: 3px;
  width: 45%;
  background-color: white;
  margin: auto;
  margin-top: 3px;
  border-radius: 15px;
}

.userLink {
  margin-left: 5px;
  margin-top: -1.5vh;
}

.userLink img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.hamburger {
  // float: right;
  display: none;
  width: 50px;
  height: 42px;
  margin-right: 10%;
  // position: relative;
  // margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 6;
  @media screen and (max-width: $small) {
    display: block;
  }
}

.hamburger span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  top: 14px;
}

.hamburger span:nth-child(4) {
  top: 28px;
}

.hamburger.open span:nth-child(1) {
  top: 14px;
  width: 0%;
  left: 50%;
}

.hamburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
  top: 14px;
  width: 0%;
  left: 50%;
}

/* .hamburger {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
} */

// #menu a:hover {
//   color: tomato;
// }

#menuDiv {
  position: relative;
  // overflow: hidden;
}

#menu {
  z-index: 5;
  background-color: #000000e8;
  // margin-top: 100px;
  visibility: hidden;
  position: absolute;
  flex-direction: column;
  display: flex;
  opacity: 0;
  // float: right;
  height: 100vh;
  width: 100vw;
  color: white;
  // left: 100vw;
  // transition: left 0.3s ease-in-out, visibility 0.3s, width 0s;
  top: -15vh;
  margin: 0;
  padding: 0;
  padding-top: 30%;
  transition: visibility 0.1s ease-out, opacity 0.1s ease-out;
  // visibility: hidden;
  @media screen and (min-width: $small) {
    visibility: hidden;
    display: none;
  }
}

#menu a {
  text-decoration: none;
  color: white;
  font-size: 25px;
  margin: 0px 0px 20px 0px;
  background-color: #000;
  // transition: color 0.3s ease;
  padding: 10px 0px;
  border-radius: 50px;
  border: 1px solid white;
}