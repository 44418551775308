$small: 650px;
$tiny: 450px;

// .leaderboardContainer {
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
// }

.leaderboardHead {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  max-width: 600px;
  margin: 2.2vh auto 0px auto;
  padding-bottom: 3px;
}

.leaderboardHead select {
  /* background-color: #1abc9c; */
  background-color: white;
  border: 0;
  height: 40px;
  color: black;
  font-size: #{"min(18px, 2.4vh)"};
  // font-family: 'Inter-Bold', arial, sans-serif;
  padding: 0.5em;
  // padding-right: 2.5em;
  /* border: 0; */
  margin: 0;
  border-radius: 3px;
  text-indent: 0.01px;
  -moz-appearance: menulist-button;
  -webkit-appearance: menulist-button;
}

.leaderboardHead h1 {
  font-size: #{"min(48px, 6vh)"};
  margin: 10px 0px;
  color: white;
  @media screen and (max-width: $small) {
    font-size: 1.5em;
  }
}

.leaderboard {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.leaderboardUsers {
  height: 100%;
  max-height: 60vh;
  // max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // flex: 0 1 80%;
  justify-content: flex-start;
  align-items: center;
  min-height: 200px;
  min-width: 0;
  padding-top: 1%;
  overflow: scroll;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), 88%, rgba(0, 0, 0, 0));
  @media screen and (max-width: $small) {
    max-height: 53vh;
  }
}

.hidden {
  display: none;
}

.leaderboardUser {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  align-items: center;
  border: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 0px 0px #{"min(20px, 2vh)"} 0px;
  background-color: #fff;
  /* width: 20%; */
  height: auto;
  min-height: 60px;
  min-width: 150px;
  overflow: hidden;
  width: 70%;
  max-width: 600px;
  max-height: 450px;
  padding: #{"min(12px, 1vh)"} 12px;
  border-radius: 15px;
  // @media screen and (max-width: $tiny) {
  //   margin: 0px 0px 10px 0px;
  // }
}

// .leaderboard button {
//   margin: 0px 10px;
// }

.names {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.leaderboardUser img {
  width: #{"min(80px, 8vh, 17vw)"};
  height: #{"min(80px, 8vh, 17vw)"};
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;
  margin: 0px #{"min(20px, 2vw)"};
}

.leaderboardUser a,
.leaderboardUser p,
.leaderboardUser h3,
.leaderboardUser h1 {
  margin: 0px;
  text-align: left;
}

.leaderboardUser a {
  text-decoration: none;
  font-size: #{"min(24px, 2.5vw)"};
  color: black;
  &:visited {
    color: #1C75BC;
  }
  &:hover {
    color: #1C75BC;
  }
  @media screen and (max-width: $tiny) {
    font-size: 14px;
  }
}

.leaderboardUser p {
  @media screen and (max-width: $tiny) {
    font-size: 12px;
  }
}

.leaderboardUser h3 {
  @media screen and (max-width: $tiny) {
    font-size: 15px;
  }
}

.highScore {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highScore h1 {
  //fallback
  font-size: 20px;
  //in browsers that support clamp
  font-size: #{"clamp(16px, (3.2vw, 4vh), 23px)"};
  color: #2BB673;
}

/* .LeaderboardUser {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  justify-content: flex-start;
  align-items: center;
  border: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 0px 10px 20px 10px;
  background-color: #fff;
  height: auto;
  min-height: 0;
  min-width: 150px;
  overflow: hidden;
  width: 20%;
  max-height: 450px;
} */

#leftArrow {
  transform: scaleX(-1);
}

.disabledArrow {
  opacity: 0.2;
}

::-webkit-scrollbar {
  display: none;
}