$small: 650px;

.userPageContainer {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 75%;
  max-width: 650px;
  margin: 0px auto;
  margin-top: 15vh;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
  @media screen and (max-width: $small) {
    margin-top: 15%;
  }
}

.userPage h1 {
  @media screen and (max-width: $small) {
    margin-bottom: 8px;
  }
}

// #logoutButton {
//   padding: 10px 0px;
//   border-radius: 20px;
//   border-width: 0px;
//   width: 100%;
//   height: auto;
//   max-width: 133.35px;
//   min-width: 60px;
//   margin-top: 10px;
//   text-align: center;
//   background: red;
//   font-size: 18px;
//   color: #000;
//   font-family: "Ubuntu", sans-serif;
//   cursor: pointer;
//   @media screen and (max-width: $small) {
//     width: 60px;
//     font-size: 12px;
//   }
// }

#logoutButton:hover {
  transform: scale(1.1);
}

#logoutButton {
  padding: 10px 20px;
  border-radius: 6px;
  border-width: 0px;
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  transition: 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #1db954;
  background: black;
  margin: 15px auto 0px auto;
  @media screen and (max-width: $small) {
    margin-top: -15px;
  }
}

#spotifyIcon {
  width: 25px;
  height: auto;
  margin-right: 8px;
}

.userHead > img {
  border-radius: 50%;
  // width: #{"clamp(115px, 20vw, 150px)"};
  width: 20vw;
  min-width: 115px;
  max-width: 150px;
  min-height: 115px;
  max-height: 150px;
  height: auto;
  transform: translateY(-50%);
}

.userName {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: -70px;
}

.userName > img {
  width: 10%;
  max-width: 30px;
  margin-left: 10px;
  /* border-radius: 100px; */
}

.stats {
  /* margin: 30px auto; */
}

.statsHead {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: auto;
}

.statsHead h2 {
  @media screen and (max-width: $small) {
    display: none;
  }
}

.statsHead select {
  /* background-color: #1abc9c; */
  background-color: white;
  border: 1px solid black;
  height: 40px;
  color: black;
  font-size: inherit;
  padding: 0.5em;
  padding-right: 2.5em;
  /* border: 0; */
  margin: 5px 0px;
  border-radius: 3px;
  text-indent: 0.01px;
  text-overflow: "";
  -moz-appearance: menulist-button;
  -webkit-appearance: menulist-button;
}

.statsBody {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  @media screen and (max-width: $small) {
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }
}

.statsBody > * {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25%;
  max-width: 200px;
  // margin: 10px 0px;
  padding: 0px 15px;
  height: 85px;
  @media screen and (max-width: $small) {
    width: 50%;
  }
}

.statsBody > * * {
  margin: 5px 0px;
}

/* this is necessary to make the (MAP NAME) show up on a new line for the overall stats */
.overallMaxScore {
  white-space: pre-line;
}

.bestOverall {
  @media screen and (max-width: $small) {
    margin-bottom: 35px;
  }
}
