$small: 650px;

$small-height: 900px;

.play-section {
  height: 92%;
  background-color: #1e75bd;
}

.countryGuessInfo {
  display: flex;
  flex-direction: column;
  width: auto;
  /* justify-content: flex-end; */
  align-items: flex-start;
  // width: 100%;
  max-height: 200px;
  /* max-width: 250px; */
  min-width: 100px;
  margin-left: 20px;
  margin-top: 10px;
}

.countryGuessInfo h2 {
  margin: 10px 0px;
  font-size: 1.5vw;
  text-align: left;
  /* width: 100%; */
  /* max-width: 133.35px; */
}

.countryGuessInfo img {
  width: 100%;
  height: auto;
  /* max-height: 100px; */
  max-width: 133.35px;
  min-width: 60px;
  /* margin-left: ; */
  /* display: block; */
  @media screen and (max-width: $small) {
    width: 60px;
  }
}

@media screen and (min-width: 1350px) {
  .countryGuessInfo h2 {
    font-size: 21px;
  }
}
@media screen and (max-width: 1000px) {
  .countryGuessInfo h2 {
    font-size: 15px;
  }
}

.countryGuessInfo button {
  padding: 10px 0px;
  border-radius: 6px;
  border-width: 0px;
  width: 100%;
  height: auto;
  max-width: 133.35px;
  min-width: 60px;
  margin-top: 10px;
  text-align: center;
  background: white;
  font-size: 18px;
  color: #000;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  @media screen and (max-width: $small) {
    width: 60px;
    font-size: 12px;
  }
}

button {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.popup-container {
  position: absolute;
  background: #000000d8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.indexText {
  position: absolute;
  right: 10%;
  top: 3%;
}

.map-div {
  height: 100%;
  width: auto;
}

.map {
  display: flex;
  flex: 1 1 auto;
}

.guess-popup {
  color: white;
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: auto;
  height: auto;
  max-height: 73%;
  margin-top: 10%;
  // background: #1db954;
  border-radius: 30px;
  padding: 20px 30px 30px 30px;
  /* border: 1px solid #999; */
  overflow: none;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  // z-index: 8;
  @media screen and (max-width: $small) {
    margin-top: 20%;
  }
}

.popup-container h1 {
  padding-top: 0px;
  margin-top: 0px;
  // z-index: 7;
}

#score {
  display: flex;
  justify-content: center;
}

// .popup-container img {
//   width: 30%;
//   height: auto;
//   object-fit: contain;
//   align-self: flex-start;
//   display: flex;
//   justify-content: flex-start;
// }

.popup-container text {
  flex: 1 1 auto;
}

.text-div {
  color: white;
  width: 40%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.text-div h3 {
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 3px;
  padding-top: 0px;
  display: flex;
}

.text-div h4 {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  display: flex;
}

.popupText {
  display: block;
}

.song-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popup-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: $small) {
    margin-top: 7px;
  }
}

@media (hover: hover) {
  .popup-buttons button:hover {
    transform: scale(1.1);
  }
}

.popup-buttons button {
  padding: 10px 20px;
  border-radius: 15px;
  border-width: 0px;
  background: white;
  font-size: 20px;
  color: #1db954;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  z-index: 1;
  transition: 0.25s ease;
}

.popup-buttons .summary-button {
  color: #333;
}

#likeButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #1db954;
  background: black;
  // padding: 5px 5px;
}

.incorrect-text button {
  color: #ee6c4d;
}

.summary {
  color: white;
  position: relative;
  width: 87%;
  max-width: 1200px;
  margin: auto;
  height: auto;
  max-height: 81vh;
  margin-top: 11vh;
  background: #333;
  border-radius: 30px;
  padding: 30px 10px;
  /* border: 1px solid #999; */
  overflow: none;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

.summary-songs-desktop {
  display: flex;
  flex-wrap: wrap;
  align-self: 1;
  justify-content: center;
  @media screen and (max-width: $small) {
    display: none;
  }
}

.summary-songs-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: $small) {
    display: none;
  }
}

.summaryItemHeader {
  display: flex;
  justify-content: center;
  margin: 8px 0px;
  @media screen and (max-width: $small) {
    flex-direction: column;
    align-items: center;
  }
}

.summaryItemHeaderName {
  display: flex;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: $small) {
    flex-direction: row;
  }
}
.summaryItemHeader img {
  margin: 0px 10px;
  width: 30px;
  @media screen and (max-width: $small) {
    margin: 4px 4px;
  }
}

.summary h1 {
  @media screen and (max-height: $small-height) {
    margin: 0;
  }
}

.summary h3 {
  @media screen and (max-width: $small) {
    margin: 5px 0px;
  }
  @media screen and (max-height: $small-height) {
    margin: 0;
  }
}

.summaryItem {
  width: 40%;
  background-color: #1db954;
  margin: 10px;
  border-radius: 20px;
  padding-bottom: 10px;
  @media screen and (max-width: $small) {
    width: 90%;
  }
}

#leftArrow {
  transform: scaleX(-1);
}

.disabledArrow {
  opacity: 0.2;
}

iframe {
  border-radius: 20px;
}

.guess-popup iframe {
  height: 30vh;
  min-height: 250px;
  max-height: 300px;
  // @media screen and (max-width: $small) {
  //   height: 240px;
  // }
  @media screen and (max-height: 610px) {
    min-height: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
}

.hidden {
  display: none;
}

.spotifyIcon {
  width: 25px;
  height: auto;
  margin-right: 8px;
}

.overlay {
  position: absolute;
  width: 100vw;
  pointer-events: none;
}

.overlay button {
  pointer-events: all;
}

.overlay audio {
  pointer-events: all;
}

.playButtonContainer {
  // margin-left: auto;
  position: absolute;
  // width: 35%;
  // display: flex;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  // margin-left:auto;
  // margin-right:auto;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: center;
}

.timeControls {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timeControls p {
  margin: 3px 0px;
}

#audioPlayer {
  display: none;
}

.audioSeeker {
  pointer-events: all;
  width: 30vw;
  max-width: 200px;
}

.volumeSeeker {
  pointer-events: all;
  width: 20vw;
  max-width: 130px;
}

.volumeControls {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $small) {
    display: none;
  }
}

.volumeControls img {
  pointer-events: all;
  width: 18px;
  height: auto;
}

.audioButton {
  pointer-events: all;
  box-sizing: border-box;
  width: 0;
  margin: auto;
  height: 40px;
  border-width: 20px 0px 20px 32px;
  max-height: 40px;
  border-style: solid;
  border-color: transparent transparent transparent #202020;
  cursor: pointer;
  will-change: border-width;
  transition: all 0.2s ease;
}
.audioButton.paused {
  border-style: double;
  border-width: 0px 0 0px 32px;
}
.audioButton:hover {
  border-color: transparent transparent transparent #404040;
}

input[type=range] {
  appearance: none;
  background: linear-gradient(to right, #fff 0%, #fff 50%, #fff 50%, #fff 100%);
  // border: solid 1px #82CFD0;
  border-radius: 8px;
  outline: none;
  opacity: 0.7;
  height: 8px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid black;
    background: white;
    cursor: pointer;
  }
}

// .incorrect-background {
//   background: #ee6c4d;
// }